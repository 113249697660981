import React from 'react'
import Image from 'gatsby-image'
import { motion } from 'framer-motion'
import Styled from 'styled-components'
import Card from './card'

const CardWrapper = Styled(motion.div)`
  box-sizing: border-box;
  background: #767676;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 40px;
  align-items: center;
  width: 100%;
  padding: 90px 40px 40px;
  min-height: 100vh;
  align-content: flex-start;
  justify-items: stretch;
  @media (max-width: 500px){
    padding: 90px 5px 40px;
  }
  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active){
    display: flex;
    flex-wrap: wrap;
    align-items: space-around;
  }
`
const cards = {
  hidden: {},
  show: {
    transition: { staggerChildren: 0.075, staggerDirection: -1 },
  },
  hover: {},
}
const SiteBlock = ({ portSite }) => {
  return (
    <CardWrapper variants={cards} initial="hidden" animate="show">
      {portSite.map(site => (
        <Card key={site.node.id}>
          <a href={site.node.url} target="_blank" rel="noopener noreferrer">
            <div className="imgContainer">
              <Image fluid={site.node.image.childImageSharp.fluid} alt={site.name} />
            </div>
          </a>
        </Card>
      ))}
    </CardWrapper>
  )
}

export default SiteBlock
