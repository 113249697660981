import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SiteBlock from '../components/SiteBlock'

const Portfolio = ({ data }) => (
  <Layout>
    <SEO title="See Our Work" />
    <SiteBlock portSite={data.allPortfolio.edges} />
  </Layout>
)
export const portfolioImages = graphql`
  query {
    allPortfolio {
      edges {
        node {
          name
          slug
          url
          id
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

export default Portfolio
