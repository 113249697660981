import React from 'react'
// import { useSpring, animated } from 'react-spring'
import { motion } from 'framer-motion'
import Styled from 'styled-components'

const CardContainer = Styled(motion.div)`
    box-sizing: border-box;
    width: 100%;
    background-color: #767676;
    border-radius: 20px;
    opacity: .7;
    box-shadow:  12px 12px 24px #565656, -12px -12px 24px #969696;
    @media (max-width: 500px){
      width: calc(100% - 40px);
    }
    .imgContainer{
      width: 95%;

      margin: 2.5% auto;
      min-height: 200px;
      .gatsby-image-wrapper {
        position: relative;
        width: 100%;
        min-height: 320px;
        border-radius: 20px;
      }
    }
    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active){
      width: 30%;
      margin-bottom: 10px;      
    }
  img{
    width: 100%;
    margin: auto;
  }  
`
const card = {
  hidden: {
    y: '-300vh',
    x: '-100vw',
    rotate: -180,
  },
  show: {
    y: 0,
    x: 0,
    rotate: 0,
    transition: {
      duration: 0.75,
    },
  },
  hover: {
    rotateX: '-10deg',
    rotateY: '-4deg',
    skew: '-1deg',
    translateY: '4px',
    scale: 0.95,
    backgroundColor: '#6a6a6a',
    opacity: 1,
    zIndex: 1000,
  },
}

// const calc = (x, y) => [-(y - window.innerHeight / 2) / 50, (x - window.innerWidth / 2) / 50, 1.1]
// const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
// const AnimatedCardContainer = motion(CardContainer)
const Card = ({ children }) => {
  return (
    <CardContainer className="profile" whileHover="hover" variants={card}>
      {children}
    </CardContainer>
  )
}
export default Card
